<template>
  <span class="amount dark:text-gray-300">
    <span v-if="isValueNegetive">-</span>
    <span v-if="hasSymbol && type == 'flat'">{{ symbol }}</span>
    <span>{{ localValue }}</span>
    <span v-if="hasSymbol && type == 'percentage'">%</span>
  </span>
</template>

<script>
import { useVepaarStore } from "@/store/store.js";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: "flat", // flat, percentage
    },
    hasSymbol: {
      type: Boolean,
      default: true,
    },
    isNegetive: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const vepaarStore = useVepaarStore();
    const decimals = computed(() => vepaarStore.currencyDecimals);
    const symbol = computed(() => vepaarStore.currencySymbol);

    return { decimals, symbol };
  },

  computed: {
    isInt() {
      return this.value % 1 === 0;
    },

    isValueNegetive() {
      return this.isNegetive || this.value < 0;
    },

    localValue() {
      if (!this.value) return 0;
      return new Intl.NumberFormat("en", {
        maximumFractionDigits: this.decimals,
        minimumFractionDigits: this.isInt ? 0 : this.decimals,
      }).format(Math.abs(this.value) || 0);
    },
  },
};
</script>

<style>
.amount {
  white-space: nowrap;
  flex-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  span + span {
    margin-inline-start: 2px;
  }
}
</style>
